<template>
    <div class="earnings-page">
        <is-loading v-if="isLoading" />
        <div v-else class="" >
            <div class="row mb-4">
                <div class="col-lg-12">
                    <earning-overview :earning="earnings.overview" />
                </div>
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <h4 class="header-title">Earnings</h4>
                                <div class="btn-group mb-2">
                                    <button type="button" class="btn btn-xs btn-light active">
                                        Today
                                    </button>
                                    <button type="button" class="btn btn-xs btn-light">Monthly</button>
                                </div>
                            </div>
                            <worker-earnings :series-data="earnings.series" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="col-12">
                    <transaction-table  />
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import IsLoading from '@/components/IsLoading.vue'
import TransactionTable from "@/components/TransactionTable.vue"
import WorkerEarnings from '@/components/widgets/WorkerEarnings.vue'
import EarningOverview from '@/components/widgets/EarningOverview.vue'

export default {
    name: 'earnings',
    components:{
        IsLoading,
        TransactionTable,
        WorkerEarnings,
        EarningOverview
    },
    data(){
        return {
            isLoading: true,
            earnings: {
                overview:{
                    current_month: 0,
                    last_month: 0,
                },
                series: []
            },
        }
    },
    computed:{
        activeUser(){
            return this.$store.state.activeUser
        },
    },
    created(){
        this.$http.get('/account/earnings')
        .then((response) => {
            this.isLoading = false
            if(response.data.success){
                this.earnings = response.data.data
            }
        })
    }

}
</script>

<style scoped>

.show-worker-earnings h2{
    font-weight: bold;
}
@media(max-width:567px){
    .show-worker-earnings h2{
        font-size:22px;
    }
    .show-worker-earnings p{
        font-size:14px;
    }
}
.display-6{
    font-size: 2rem;
}
</style>
