
<template>
<div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header bg-white">
          <h4 class="mb-0">Transactions list</h4>
        </div>
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-12 col-md-8">
              <div id="tickets-table_length" class="dataTables_length">
                <button @click="popupModalShow = !popupModalShow" class="btn mb-1 mr-2 btn-primary" type="button">Withdraw</button>
                <label class="d-inline-flex mb-2 mr-2 align-items-center">
                  <b-form-select v-model="itemsPerPage" :options="pageOptions"></b-form-select>
                </label>
                <label class="d-block d-sm-inline-flex mb-2 align-items-center">
                  <b-form-select v-model="defaultStatus" :options="statusOptions"></b-form-select>
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-4">
              <div class="">
                <label class="d-block">
                  <b-form-input v-model="searchQuery" @input="updateSearchQuery" type="search" placeholder="Search..." class="form-control"></b-form-input>
                </label>
              </div>
            </div>
          </div>
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover table-class="table font-sm table-centered w-100" thead-tr-class=""
              ref="selectableTable" :items="orders" :fields="columns" responsive="sm">
                <template v-slot:cell(account_name)="data">
                    <p class="m-0 d-inline-block cursor-pointer align-middle">
                      <span class="text-dark font-sm">{{ data.item.account_name }} </span>
                    </p>
                </template>
                <template v-slot:cell(buyer)="data">
                    <p class="m-0 d-inline-block cursor-pointer align-middle">
                    <span class="text-dark font-sm">{{ data.item.bank_name }}</span>
                    </p>
                </template>
                <template v-slot:cell(bank) = "data">
                    <div style="min-width:5rem;">
                    {{ data.item.bank_name }}
                    </div>
                </template>
                <template v-slot:cell(status) = "data">
                    <span v-html="statusBadge(data.item.status)"></span>
                </template>
                <template v-slot:cell(date)="data">
                  <div style="min-width:5rem;" class="mb-0 cursor-pointer">
                    <span class="d-block">{{ data.item.created_at | date_time(1) }} </span>
                  </div>
                </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="paging_simple_numbers float-right">
                <b-pagination v-model="currentPage" class="pagination-rounded mb-0"
                  :total-rows="pageData.total" :limit="3" :per-page="itemsPerPage"></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <b-modal size="md" title="Withdrawal Request"
    body-bg-variant="light" body-class="px-3 py-4"
    hide-footer v-model="popupModalShow">
        <transaction-form @closeModal="popupModalShow = false" />
    </b-modal>
</div>

</template>

<script>

import IsLoading from '@/components/IsLoading.vue';
import TransactionForm from '@/components/forms/TransactionForm.vue';
import _ from 'lodash';

export default {
  components:{
    IsLoading,
    TransactionForm
  },
  name:"order-list-table",
  data() {
    return {
      isLoading: true,
      defaultStatus:"all",
      searchQuery:"",
      popupModalShow: false,
      pageOptions: [10, 25, 50, 100],
      statusOptions: [
        {value: "all", text: "All"},
        {value: "pending", text: "Pending"},
        {value: "paid", text: "Paid"},
        {value: "cancelled", text: "Cancelled"}
      ],
      selected: [],
      isSelectAll: false,
      columns: [
          {
              key: "account_name",
              label: "Account name",
              sortable: true
          },
          {
              key: "bank",
              sortable: true
          },
          {
              key: "amount",
              sortable: true
          },
           {
              key: "status",
          },
          {
              key: "date",
              label: "Date",
          }
      ],
    }
  },
  watch: {
    "$route.query":"refreshQueryStatus",
    defaultStatus (val) {
      this.fetchItems()
    }
  },
  computed: {
    pageData(){
      return this.$store.state.transaction.pageData
    },
    orders() {
        return this.pageData.data.filter((order) => this.defaultStatus == "all" 
            || (order.status == this.defaultStatus))
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
  },
  methods: {
    navigateTo(id){
      this.$router.push({path:`/orders/${id}`});
    },
    refreshQueryStatus(){
        if(this.$route.query.status){
          this.defaultStatus = this.$route.query.status
        }
    },
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.orders.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.defaultStatus != 'all'){ payload.status = this.defaultStatus }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("transaction/fetchTransactions", payload)
      .then(response => this.isLoading = false)
    },
  },
  mounted() {
    this.refreshQueryStatus()
    if(this.defaultStatus == "all"){this.fetchItems()}
  },
}

</script>

<style lang="scss">
.font-sm{
  font-size:13px;
}
</style>
